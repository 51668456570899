// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-cn-js": () => import("./../../../src/pages/about.cn.js" /* webpackChunkName: "component---src-pages-about-cn-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-buddhism-and-psychology-cn-js": () => import("./../../../src/pages/buddhism_and_psychology.cn.js" /* webpackChunkName: "component---src-pages-buddhism-and-psychology-cn-js" */),
  "component---src-pages-buddhism-and-psychology-en-js": () => import("./../../../src/pages/buddhism_and_psychology.en.js" /* webpackChunkName: "component---src-pages-buddhism-and-psychology-en-js" */),
  "component---src-pages-events-cn-js": () => import("./../../../src/pages/events.cn.js" /* webpackChunkName: "component---src-pages-events-cn-js" */),
  "component---src-pages-events-en-js": () => import("./../../../src/pages/events.en.js" /* webpackChunkName: "component---src-pages-events-en-js" */),
  "component---src-pages-freud-and-china-cn-js": () => import("./../../../src/pages/freud_and_china.cn.js" /* webpackChunkName: "component---src-pages-freud-and-china-cn-js" */),
  "component---src-pages-freud-and-china-en-js": () => import("./../../../src/pages/freud_and_china.en.js" /* webpackChunkName: "component---src-pages-freud-and-china-en-js" */),
  "component---src-pages-index-cn-js": () => import("./../../../src/pages/index.cn.js" /* webpackChunkName: "component---src-pages-index-cn-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-origins-of-chinese-phil-cn-js": () => import("./../../../src/pages/origins_of_chinese_phil.cn.js" /* webpackChunkName: "component---src-pages-origins-of-chinese-phil-cn-js" */),
  "component---src-pages-origins-of-chinese-phil-en-js": () => import("./../../../src/pages/origins_of_chinese_phil.en.js" /* webpackChunkName: "component---src-pages-origins-of-chinese-phil-en-js" */),
  "component---src-pages-papers-cn-js": () => import("./../../../src/pages/papers.cn.js" /* webpackChunkName: "component---src-pages-papers-cn-js" */),
  "component---src-pages-papers-en-js": () => import("./../../../src/pages/papers.en.js" /* webpackChunkName: "component---src-pages-papers-en-js" */),
  "component---src-pages-twitter-cn-js": () => import("./../../../src/pages/twitter.cn.js" /* webpackChunkName: "component---src-pages-twitter-cn-js" */),
  "component---src-pages-twitter-en-js": () => import("./../../../src/pages/twitter.en.js" /* webpackChunkName: "component---src-pages-twitter-en-js" */)
}

